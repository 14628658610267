import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Helmet from "react-helmet"
import Slider from "react-slick"

import SEO from "../components/seo"
import Layout from "../components/layout"
import { TransitionMixin, media } from "../components/helpers"
import Wrapper from "../components/wrapper"

const BannerImageContainer = styled.div`
  position: relative;
  .wrapper-class {
    left: 0;
    right: 0;
    position: absolute;
    top: 16vh;

    ${media.medium`top: 24vh;`}
    ${media.xl`top: 27vh;`}
    .content-container {
      max-width: 400px;
      margin: 0 auto;
      text-align: center;
      ${media.medium`margin: 0 ;text-align: left;`}
      ${media.large`max-width: 450px;`}

      h1 {
        margin: 0 0 30px;
        color: #fff;
        line-height: 1.4;
        font-size: 30px;

        ${media.large`font-size: 40px;`}
      }

      p {
        line-height: 1.5;
        color: #fff;
        margin: 0 0 30px;
        font-size: 17px;
        font-weight: 300;
      }

      .btn-wrap {
        a {
          max-width: 250px;
          width: 100%;
          display: inline-block;
          color: #2a2a2a;
          background-color: #fff;
          text-decoration: none;
          padding: 10px 0;
          border-radius: 20px;
          font-weight: bold;
          line-height: 1;
          text-align: center;
          ${TransitionMixin(".25s")};

          &:hover {
            background-color: #2a2a2a;
            color: #fff;
          }
        }
      }
    }
  }

  .desktop-banner {
    .box-image {
      position: absolute;
      width: 100%;
      top: 16vh;
      right: 8vw;
      max-width: 400px;
      ${media.medium`max-width: 460px;`}
      ${media.large`max-width: 630px;`}
      ${media.xl`top: 23vh;right: 8vw; max-width: 650px;`}
      .gatsby-image-wrapper {
      }
    }
    .desktop-image {
      display: none;
      overflow: hidden;
      ${media.medium`display: block;  max-height: 80vh;`}
      ${media.large`height: 80vh;`}
    }
  }

  .mobile-banner {
    margin-bottom: 20px;
    ${media.medium`margin-bottom: 0px; display: none;`}
    .box-image {
      position: absolute;
      width: 100%;
      bottom: -80px;

      .gatsby-image-wrapper {
        max-width: 300px;
        margin: 0 auto;
        ${media.medium`max-width: 430px;`}
        
      }
    }
  }
  .mobile-image {
    display: block;
    ${media.large`display: none;`}
    .gatsby-image-wrapper {
      height: 80vh;
      overflow: hidden;
      ${media.large`height: 0;`}
    }
  }
`

const AboutContainer = styled.section`
  padding: 100px 0 70px;
  text-align: center;
  .title-wrap {
    margin: 0 0 20px;
    h2 {
      margin: 0;
      ${media.medium`font-size: 20px;`};
    }
  }
  .wrapper-class {
    p {
      font-size: 12px;
      font-weight: 300;
      max-width: 570px;
      margin: 0 auto;
    }
  }
`

const FeaturedLogoContainer = styled.section`
  padding: 70px 0px;
  background-color: #fafafa;
  .title-wrap {
    margin: 0 0 50px;
    text-align: center;
    h2 {
      margin: 0;
      ${media.medium`font-size: 20px;`};
    }
  }

  .logo-grid {
    max-width: 1200px;
    margin: 0 auto;
    .flex-wrap {
      .logo-container {
        flex: 1 1 100%;
        display: flex;
        padding: 40px 0;
        ${media.medium`flex: 1 1 50%;padding: 30px 0;`};
        .inner-wrap {
          flex: 1;
          align-self: center;
          max-width: 300px;
          max-height: 150px;
          margin: 0 auto;

          img {
            max-height: 150px;
            margin-bottom: 0px !important;
            object-fit: contain !important;
          }
        }
        &:nth-child(1) {
          ${media.medium`max-width: 600px;border-right: 1px solid #d4d4d4;border-bottom: 1px solid #d4d4d4;`};
        }
        &:nth-child(2) {
          padding: 30px 0;
          ${media.medium`border-bottom: 1px solid #d4d4d4;`};
          .inner-wrap {
            max-height: 50px;
          }

          img {
            max-height: 50px;
          }
        }
        &:nth-child(3) {
          ${media.medium`border-right: 1px solid #d4d4d4;`};
        }
      }
    }
  }
`

const SplitContentContainer = styled.section`
  padding: 70px 0px;
  .flex-wrap {
    .content-container {
      flex: 1 1 100%;
      margin-bottom: 40px;
      ${media.medium`flex: 1;margin-bottom: 0px;`};

      .inner-wrap {
        text-align: center;
        ${media.medium`text-align: left;`};
        h2 {
          margin-bottom: 20px;
          color: #1e1e1e;
          ${media.medium`font-size: 20px;`};
        }
        p {
          max-width: 400px;
          font-size: 14px;
          font-weight: 300;
          margin: 0 auto;
          ${media.medium`margin: 0;`};
          ${media.xl`max-width: 600px;`};
        }
      }
    }
    .icon-grid-container {
      margin-bottom: -50px;
      flex: 1 1 100%;
      ${media.medium`flex: 1;`};
      .inner-wrap {
        display: flex;
        flex-wrap: wrap;

        .icon-container {
          flex: 0 0 33%;
          margin-bottom: 50px;
          .image-container {
            max-width: 70px;
            margin: 0 auto 20px;
          }
          .title-container {
            text-align: center;
            p {
              margin: 0;
              font-size: 14px;
              color: #1e1e1e;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
`

const IconContainer = styled.section`
  padding: 70px 0px;
  background-color: #fafafa;
  .title-wrap {
    margin: 0 0 80px;
    text-align: center;
    h2 {
      margin: 0;
      color: #1e1e1e;
      ${media.medium`font-size: 20px;`};
    }
  }
  .flex-wrap {
    .icon-container {
      flex: 1 1 100%;
      margin-bottom: 40px;
      ${media.medium`flex: 1; margin-bottom: 0px;`};

      .icon-image {
        max-width: 80px;
        margin: 0 auto 15px;
      }
      .icon-content {
        text-align: center;
        h4 {
          color: #1e1e1e;
          margin: 0 0 7px;
          font-size: 12px;
        }
        p {
          font-size: 12px;
          font-weight: 300;
          max-width: 270px;
          margin: 0 auto;
          line-height: 24px;
        }
      }
    }
  }
`
const FormContainer = styled.section`
  padding: 70px 0;
  background-color: #fafafa;
  .title-wrap {
    margin: 0 0 80px;
    text-align: center;
    h2 {
      margin: 0;
      color: #1e1e1e;
      ${media.medium`font-size: 20px;`};
    }
  }
  form {
    max-width: 550px;
    margin: 0 auto;

    .input-wrap {
      display: flex;
      margin-bottom: 20px;

      input {
        flex: 1;
        width: 100%;
        border-radius: 186.5px;
        border: 1px solid #979797;
        padding: 10px 20px;
        font-family: "Montserrat", sans-serif !important;
        font-weight: 300;
        font-size: 16px;
      }
      button {
        flex: 1;
        border-radius: 186.5px;
        padding: 10px 0px;
        background-color: #1ab78d;
        color: #fafafa;
        font-weight: bold;
        font-family: "Montserrat", sans-serif !important;
      }
    }
  }
`

const TestimonialContainer = styled.section`
  padding: 70px 0;

  .title-wrap {
    margin: 0 0 80px;
    text-align: center;
    h2 {
      margin: 0;
      color: #1e1e1e;
      ${media.medium`font-size: 20px;`};
    }
  }
  .slick-slider {
    display: flex;
    .slick-list {
      flex: 3;
      order: 2;

      .slide-container {
        &:focus {
          outline: 0;
        }
        .inner-wrap {
          margin: 10px 40px;
          padding: 40px;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
          ${media.medium`min-height: 295px;`}
          .image-container {
            flex: 1 1 100%;
            ${media.medium`flex: 1;`}
            .gatsby-image-wrapper {
              max-width: 35px;
              width: 35px;
            }
          }
          .content-container {
            margin-top: 40px;

            p {
              font-family: "Raleway", sans-serif !important;
              font-style: italic;
              font-size: 20px;
              line-height: 30px;
            }
          }
        }
      }
    }
    .slick-dots {
      flex: 0.4;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;

      ul {
        margin: 30px 0 0;
        ${media.medium`transform: rotate(90deg);`};
        li {
          display: block;
          ${media.medium`display: inline-block;`};
          button:before {
            width: 10px;
            height: 10px;
            color: #b3b3b3;
            opacity: 0.5;
            font-size: 16px;
          }
        }
        li.slick-active button::before {
          opacity: 0.9;
        }
      }
    }
  }
`

class TestimonialSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
      appendDots: dots => (
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
    }
    let { slides, icon } = this.props
    return (
      <Slider {...settings}>
        {slides.map(slide => {
          function createMarkup() {
            return { __html: slide.testimonial }
          }

          return (
            <div className="slide-container">
              <div className="inner-wrap">
                <div className="image-container">
                  <Img fluid={icon}></Img>
                </div>
                <div className="content-container">
                  <div dangerouslySetInnerHTML={createMarkup()} />
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    )
  }
}

const HostPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "boxcrop.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      apple: file(relativePath: { eq: "apple.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      android: file(relativePath: { eq: "android.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      quote: file(relativePath: { eq: "quote.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allWordpressPage(filter: { title: { eq: "Host" } }) {
        edges {
          node {
            title
            acf {
              banner_headline
              banner_image_desktop {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              banner_image_mobile {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              banner_button {
                url
                title
              }
              section_title
              section_description
              featured_logos_section_title
              split_section_title
              split_section_content
              icon_section_title
              testimonial_section_title
              testimonials {
                testimonial
              }
              split_section_icons {
                icon_title
                icon_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              # logos {
              #   logo_image {
              #     localFile {
              #       childImageSharp {
              #         fluid(maxWidth: 400) {
              #           ...GatsbyImageSharpFluid
              #         }
              #       }
              #     }
              #   }
              # }
              icons {
                icon_title
                icon_blurb
                icon_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 250) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const page = data.allWordpressPage.edges[0].node
  function createDescriptionMarkup() {
    return { __html: page.acf.section_description }
  }
  function createIconContentMarkup() {
    return { __html: page.acf.split_section_content }
  }

  console.log("data", data)
  return (
    <Layout>
      <SEO title="Host" />
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <BannerImageContainer>
        <div className="desktop-banner">
          <div className="banner-image desktop-image">
            <Img
              fluid={
                page.acf.banner_image_desktop.localFile.childImageSharp.fluid
              }
            ></Img>
          </div>
          <div className="box-image">
            <Img fluid={data.bannerImage.childImageSharp.fluid}></Img>
          </div>
        </div>
        <div className="mobile-banner">
          <div className="banner-image mobile-image">
            <Img
              fluid={
                page.acf.banner_image_mobile.localFile.childImageSharp.fluid
              }
            />
          </div>
          <div className="box-image">
            <Img fluid={data.bannerImage.childImageSharp.fluid}></Img>
          </div>
        </div>
        <Wrapper styleClass size="md mobile-md">
          <div className="content-container">
            <h1>{page.acf.banner_headline}</h1>
            <div className="btn-wrap">
              <a href={page.acf.banner_button.url}>
                {page.acf.banner_button.title}
              </a>
            </div>
          </div>
        </Wrapper>
      </BannerImageContainer>
      <AboutContainer>
        <Wrapper>
          <div className="title-wrap">
            <h2>{page.acf.section_title}</h2>
          </div>
          <Wrapper size="xs" styleClass>
            <div dangerouslySetInnerHTML={createDescriptionMarkup()} />
          </Wrapper>
        </Wrapper>
      </AboutContainer>

      {page.acf.logos && (
        <FeaturedLogoContainer>
          <Wrapper>
            <div className="title-wrap">
              <h2>Featured Partners</h2>
            </div>
            <div className="logo-grid">
              <Wrapper size="md" activeClass flex>
                {page.acf.logos &&
                  page.acf.logos.map(logo => {
                    console.log(logo)
                    return (
                      <div className="logo-container">
                        <div className="inner-wrap">
                          <Img
                            fluid={
                              logo.logo_image.localFile.childImageSharp.fluid
                            }
                          />
                        </div>
                      </div>
                    )
                  })}
              </Wrapper>
            </div>
          </Wrapper>
        </FeaturedLogoContainer>
      )}
      <SplitContentContainer>
        <Wrapper size="sm" flex>
          <div className="content-container">
            <div className="inner-wrap">
              <h2>{page.acf.split_section_title}</h2>
              <div dangerouslySetInnerHTML={createIconContentMarkup()} />
            </div>
          </div>
          <div className="icon-grid-container">
            <div className="inner-wrap">
              {page.acf.split_section_icons.map(icon => {
                return (
                  <div className="icon-container">
                    <div className="image-container">
                      <Img
                        fluid={icon.icon_image.localFile.childImageSharp.fluid}
                      />
                    </div>
                    <div className="title-container">
                      <p>{icon.icon_title}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Wrapper>
      </SplitContentContainer>
      <IconContainer>
        <Wrapper>
          <div className="title-wrap">
            <h2>{page.acf.icon_section_title}</h2>
          </div>
          <Wrapper flex>
            {page.acf.icons.map(icon => {
              return (
                <div className="icon-container">
                  <div className="icon-image">
                    <Img
                      fluid={icon.icon_image.localFile.childImageSharp.fluid}
                    />
                  </div>
                  <div className="icon-content">
                    <h4>{icon.icon_title}</h4>
                    <p>{icon.icon_blurb}</p>
                  </div>
                </div>
              )
            })}
          </Wrapper>
        </Wrapper>
      </IconContainer>
      <TestimonialContainer>
        <div className="controls-container"></div>
        <div className="slider-container">
          <div className="title-wrap">
            <h2>{page.acf.testimonial_section_title}</h2>
          </div>
          <TestimonialSlider
            icon={data.quote.childImageSharp.fluid}
            slides={page.acf.testimonials}
          />
        </div>
      </TestimonialContainer>
      <FormContainer>
        <div className="title-wrap">
          <h2>Get a FREE ChargeTree Kiosk installed at your Business today!</h2>
        </div>
        <Wrapper>
          <form>
            <div className="input-wrap">
              <input type="text" placeholder="My name is" />
            </div>
            <div className="input-wrap">
              <input type="text" placeholder="My business is" />
            </div>
            <div className="input-wrap">
              <input type="text" placeholder="I'm located in" />
            </div>
            <div className="input-wrap">
              <input type="text" placeholder="My phone is" />
            </div>
            <div className="input-wrap">
              <input type="text" placeholder="Best time to contact me" />
            </div>
            <div className="input-wrap">
              <button>Sign Up Free</button>
            </div>
          </form>
        </Wrapper>
      </FormContainer>
    </Layout>
  )
}

export default HostPage
